import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-booking',
	templateUrl: './booking.component.html',
	styleUrls: [ './booking.component.scss', '../app.component.scss' ]
})
export class BookingComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}

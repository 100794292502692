import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-treatments',
	templateUrl: './treatments.component.html',
	styleUrls: [ './treatments.component.scss', '../app.component.scss' ]
})
export class TreatmentsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'therapists',
	templateUrl: './therapists.component.html',
	styleUrls: [ './therapists.component.scss', '../app.component.scss' ]
})
export class TherapistsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
